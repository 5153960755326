(function($){
    'use strict';

    function stopVideo() {
        $('.video__popup-player').each(function() {
            $(this)[0].contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');
        });
    }

    $(".video__button").click(function() {
        var $currentVideoContainerEl = $(this).parents('.video__container');
        $currentVideoContainerEl.find('.video__popup--background').toggle('visible');
        $currentVideoContainerEl.find('.video__popup').toggleClass("visible");
        if ($(this).hasClass('video__close')) {
            stopVideo();
        }

    });
    $(".video__popup--background").click(function() {
        var $currentVideoContainerEl = $(this).parents('.video__container');
        $(this).toggle("visible");
        $currentVideoContainerEl.find('.video__popup').toggleClass("visible");
        stopVideo();
    });
}(jQuery));