(function($) {
    'use strict';

    $('.card-grid--slider').each(function() {
        var itemsToSlide = $(this).find($('.card-grid__item')).length;
        var minimumSlidesMobile = 1;
        var minimumSlidesDesktop = 3;
        var desktopBreakpoint = 1200;

        if ((window.innerWidth < desktopBreakpoint) && (itemsToSlide > minimumSlidesMobile)) {
            $(this).find($('.card-grid__list')).slick({
                infinite: false,
                mobileFirst: true,
                slidesToShow: 1,
                responsive: [
                    {
                        breakpoint: desktopBreakpoint,
                        settings: {
                            slidesToShow: 3
                        }
                    }
                ]
            });
        } else if ((window.innerWidth >= desktopBreakpoint) && (itemsToSlide > minimumSlidesDesktop)) {
            $(this).find($('.card-grid__list')).slick({
                infinite: false,
                mobileFirst: true,
                slidesToShow: 1,
                responsive: [
                    {
                        breakpoint: desktopBreakpoint,
                        settings: {
                            slidesToShow: 3
                        }
                    }
                ]
            });
        }
    });
})(jQuery);
