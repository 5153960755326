/**
 * @file Header Mobile menu toggle
 * 
 * @author Konrad Kaliszewski <kkaliszewski@squiz.pl>
 * @date October 2018
 */

var headerMobileMenu = (function () {
    var $mobileMenuToggleEl = $('.header__mobile-menu-toggle');
    var $headerEl = $('.main-header');
    var $headerNavLvl1ListEl = $('.header-nav-l1__list');
    var $headerLinksEl = $('.header-links');
    var $headerSearchEl = $('.header-search');

    var headerNavLvl2ActiveClass = 'header-nav-l1__list--l2-active';
    var headerNavLvl3ActiveClass = 'header-nav-l1__list--l3-active';
    var headerSectionHiddenClass = 'header-section--hidden';

    var menuActiveClass = 'main-header--mobile-menu-active';

    // Toggles mobile menu
    function toggleMobileMenu() {
        var ariaExpanded = $mobileMenuToggleEl.attr('aria-expanded');
        var expanded;
        switch (ariaExpanded) {
            case "true": {
                expanded = true;
                break;
            }
            case "false": {
                expanded = false;
                break;
            }
            default: {
                break;
            }
        }
        $headerNavLvl1ListEl.removeClass(headerNavLvl2ActiveClass);
        $headerNavLvl1ListEl.removeClass(headerNavLvl3ActiveClass);
        $headerLinksEl.removeClass(headerSectionHiddenClass);
        $headerSearchEl.removeClass(headerSectionHiddenClass);
        $mobileMenuToggleEl.attr('aria-expanded', !expanded);
        $headerEl.toggleClass(menuActiveClass);
    }

    // Function disable onClick for Services
    // function disableLvl1Click() {
    //     $('.header-nav-l1__link').each(function() {
    //         if ($(this).text().includes('Services')) {
    //             $(this).click(function(e) {
    //                 e.preventDefault();
    //             });
    //         }
    //     });
    // }

    // Binds all the events
    function bind() {
        $mobileMenuToggleEl.click(function() {
            toggleMobileMenu();
        });
    }

    /**
     * Initialisation
     */
    return {
         init: function() {
            bind();
            // disableLvl1Click();
        }
    }
})();

(function() {
    headerMobileMenu.init();
}(jQuery));