/**
 * @file Header Search toggle
 * 
 * @author Konrad Kaliszewski <kkaliszewski@squiz.pl>
 * @date October 2018
 */

var headerSearchToggle = (function () {
    var $headerSearchToggle = $('.header-search__toggle');
    var $mainHeaderEl = $('.main-header');
    var $searchEl = $('.header-search__form');

    var headerActiveClass = 'main-header--submenu-open';


    // Toggles mobile menu
    function toggleHeaderSearch() {
        var ariaExpanded = $headerSearchToggle.attr('aria-expanded');
        var expanded;
        switch (ariaExpanded) {
            case "true": {
                expanded = true;
                break;
            }
            case "false": {
                expanded = false;
                break;
            }
            default: {
                break;
            }
        }
        $mainHeaderEl.toggleClass(headerActiveClass);
        $headerSearchToggle.attr('aria-expanded', !expanded);
    }

    // Binds all the events
    function bind() {
        $headerSearchToggle.click(function() {
            toggleHeaderSearch();
        });
    }

    /**
     * Initialisation
     */
    return {
         init: function() {
            bind();
        }
    }
})();

(function() {
    headerSearchToggle.init();
}(jQuery));