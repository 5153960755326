/**
 * @file Links box with tabs
 * 
 * @author Konrad Kaliszewski <kkaliszewski@squiz.pl>
 * @date December 2018
 */

var linksBoxTab = (function () {

  var initialActiveItem = document.querySelector('.links-box__link');


  var $linkBoxLinkEl = $('.links-box__link');
  var $textOnImageTab = $('.text-on-image-tab');

  var activeTabClass = 'links-box__link--active';
  var textOnImageActiveClass = 'text-on-image-tab--active';

  // Toggles mobile menu
  function tabToggle(link) {
    $textOnImageTab.each(function() {
      $(this).removeClass(textOnImageActiveClass);
    });

    $linkBoxLinkEl.each(function() {
      $(this).removeClass(activeTabClass);
    });


    var $activeTab = $(link);
    var $activeTabContent = $(link.hash);

    $activeTab.addClass(activeTabClass);
    $activeTabContent.addClass(textOnImageActiveClass);
    

    
  }

  // Binds all the events
  function bind() {
    $linkBoxLinkEl.click(function(e) {
      e.preventDefault();
      tabToggle(e.target);
    })
  }

  /**
   * Initialisation
   */
  return {
       init: function() {
          bind();
          tabToggle(initialActiveItem);
      }
      
      
  }
})();

(function() {
  if ($('.links-box').length > 0) {
    linksBoxTab.init();
  }
}(jQuery));