/**
 * @file Sets widget poistion on mobile
 * 
 * @author Konrad Kaliszewski <kkaliszewski@squiz.pl>
 * @date February 2019
 */

var widgetPositionMobile = (function () {

  function setWidgetPosition() {
    if ($('body').hasClass('page--service-details')) {
      if (window.innerWidth < 1150) {
        if ($('main .container > .card-grid').length > 0) {
          $('main .container > .card-grid:eq(0)').before($('.inside-widget'));
        } else {
          $('main .container').append($('.inside-widget'));
        }
      } else {
        resetWidgetPosition();
      }
    }
  }

  function resetWidgetPosition() {
    $('.two-col__aside').prepend($('.inside-widget'));
  }

  function bind() {
    var resizeTimer;
    $(window).on('resize', function (e) {

      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(function () {

        setWidgetPosition();

      }, 250);

    });
  }

  /**
   * Initialisation
   */
  return {
    init: function () {
      bind();
      setWidgetPosition();
    }
  }
})();

(function () {
  widgetPositionMobile.init();
}(jQuery));