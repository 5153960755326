(function() {
  $('.selectize').selectize({
    onInitialize: function() {
      $('#filters__services-selectized').attr('aria-label','Fitler');
    },
    onChange: function() {
      var url = this.getValue();
      window.location.href = url;
    }
  });
}(jQuery));