// $(".home-banner__scroll").click(function() {
//   $([document.documentElement, document.body]).animate({
//       scrollTop: $("#site-menu").offset().top
//   }, 500);
// });

(function($){
  'use strict';

  if($('.home-hero__video').length) {
  var tag = document.createElement('script');
  tag.src = "//www.youtube.com/player_api";
  var firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    $('.home-hero__video').each(function() {
      var $video = $(this),
        videoID = $video.attr('data-video');

      $video.append('<div id="video-' + videoID + '"></div>');

        // NOTE: using set/clear interval due to race condition loading YouTube API
        var interval = setInterval(function() {
            if (isYouTubeApiLoaded()) {
            new YT.Player('video-' + videoID, {
              videoId: videoID,
          playerVars: {
            'controls': 0,           
            'showinfo': 0,
            'rel': 0
          },
                events: {
                    'onReady': function(e) {
                      e.target.playVideo();
                        e.target.mute();
                    },
                    'onStateChange': function(e) {
                        if (e.data == YT.PlayerState.PLAYING) {
                          $video.find('iframe').css('opacity', '1');
                  }

                        if (e.data === YT.PlayerState.ENDED) {
                      e.target.playVideo();
                  }
                    }
                }
            });
                clearInterval(interval);
            }
        }, 100);
    });
  }

  // Checks if 'YT' object exists (part of YouTube API)
  function isYouTubeApiLoaded() {
      if (window['YT'] && typeof window['YT'].Player === 'function') {
          return true;
      } else {
          return false;
      }
}

function playPauseVideo() {

  var player;
  window.onYouTubeIframeAPIReady = function() {
    player = new YT.Player('myVideo');
  }

  $('.icon-pause').click(function() {
    $('.home-banner__video iframe').get(0).contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}','*');
    $('.icon-pause').css('display', 'none');
    $('.icon-play').css('display', 'block');
  });

  $('.icon-play').click(function() {
    $('.home-banner__video iframe').get(0).contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}','*');
    $('.icon-pause').css('display', 'block');
    $('.icon-play').css('display', 'none');		
  });
}

playPauseVideo()

}(jQuery));
