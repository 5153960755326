/*global jQuery*/
/**
 * {{name}}
 * Global JS
 *
 * version: {{version}}
 * file:    {{file}}
 * author:  Squiz Australia
 * change log:
 *     {{date}} - First revision
 */

/*
 * Table of Contents
 *
 * - Global
 * - Modules
{{toc}}
 */
'use strict';
/*
--------------------
Global
--------------------
*/
//  Declare JS Enabled.
$('body').removeClass('no-js').addClass('js-enabled');


/*
--------------------
Modules
--------------------
*/

/*
--------------------
Polyfills
--------------------
*/

// SVG IE11
svg4everybody();

const applyObjectfit = () => {
  const $html = $('html');
  if (!$html.hasClass('object-fit')) {
      $('.js-objectfit').each(function() {
          let $container = $(this),
          imgUrl = $container.find('img').prop('src');

          if (imgUrl) {
              $container.css('backgroundImage', 'url(' + imgUrl + ')').addClass('compat-object-fit');
          }
      });
  }
};
applyObjectfit();
