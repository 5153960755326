/**
 * @file Contact map offices
 * 
 * @author Konrad Kaliszewski <kkaliszewski@squiz.pl>
 * @date November 2018
 */

var headerMobileMenu = (function () {
    var $mapPath = $('.contact-map path');
    var $contactRegion = $('.contact-map__region');
    var $contactPerson = $('.contact-person__item');
    var $contactOffice = $('.contact-offices__office');
    var $contactOfficesWrapperEl = $('.contact-offices');
    
    var mapActiveClass = 'mapactive';
    var activeRegionLabelClass = 'contact-map__region--active';
    var contactPersonActiveClass = 'contact-person__item--active';
    var contactOfficeActiveClass = 'contact-offices__office--active';
    var contactOfficesWrapperHiddenClass = 'contact-offices--hidden';

    // var initActiveRegion = 'mapsydney'

    // SUPPORT-401607, No default region desired
    var initActiveRegion = 'mapdefault';

    /**
     * Returns active region for office specified in the URL by assetID.
     */
    function getActiveRegion() {
        var activeRegion = initActiveRegion;
        var redirectedOffice = window.location.hash;
        if (redirectedOffice) {
            $(redirectedOffice).parents('.contact-offices__office')[0].classList.forEach((cl) => {
                if (cl.includes('--') === true) {
                    var classParts = cl.split('--');
                    var region = classParts[classParts.length - 1];
                    activeRegion = "map"+region;
                    console.log(activeRegion);
                }
            });
        }
        return activeRegion;
    }

    function setActiveRegion(activeRegionName) {
        $contactOfficesWrapperEl.removeClass(contactOfficesWrapperHiddenClass);
        $mapPath.each(function() {
            $(this).removeClass(mapActiveClass);
        });
        $contactRegion.each(function() {
            $(this).removeClass(activeRegionLabelClass);
        });
        $contactPerson.each(function() {
            $(this).removeClass(contactPersonActiveClass);
        });
        $contactOffice.each(function() {
            $(this).removeClass(contactOfficeActiveClass);
        });

        var $activeRegionEl = $('#'+activeRegionName);
        var regionName = activeRegionName.replace('map','');
        var $activeRegionLabelEl = $('.contact-map__region-'+regionName);
        var $activePersonEl = $('.contact-person__item--'+regionName);
        var $activeOfficeEl = $('.contact-offices__office--'+regionName);
        $activeRegionLabelEl.addClass(activeRegionLabelClass);
        $activeRegionEl.addClass(mapActiveClass);
        $activePersonEl.addClass(contactPersonActiveClass);
        $activeOfficeEl.addClass(contactOfficeActiveClass);
        
    }

    // Binds all the events
    function bind() {
        $mapPath.click(function(e) {
            var activeRegion = $(e.target).attr('id');
            setActiveRegion(activeRegion);
        });
        $contactRegion.click(function() {
            var activeRegion = $(this).data('region');
            setActiveRegion(activeRegion);
        })
    }

    /**
     * Initialisation
     */
    return {
         init: function() {
            bind();
            setActiveRegion(getActiveRegion());
        }
    }
})();

(function() {
    headerMobileMenu.init();
}(jQuery));