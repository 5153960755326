(function ($) {
	'use strict';

	//** Autocomplete */

	// Standard query completion.
	$('.js-typeahead-query-standard').qc({
		datasets: {
			organic: {
				collection: 'pwa-web',
				profile: '',
				program: 'https://nswdfsi-search.squiz.cloud/s/suggest.json',
				show: '5',
				template: {

					suggestion: function (context) {
						var itemResult = '<div class="search-suggest-result-standard__container">%LABEL%</div>';

						itemResult = itemResult.replace('%LABEL%', String(context.label));

						return itemResult;
					}
				}
			}
		},
		length: 3,
		interactionLog: ''
	});

}(jQuery));