/**
 * @file Header mobile navigation 
 * 
 * @author Konrad Kaliszewski <kkaliszewski@squiz.pl>
 * @date October 2018
 */

var headerMobileMenu = (function () {
    var $submenuToggleEl = $('.header-nav__submenu-toggle');
    var $topLevelMenuEl = $('.header-nav-l1__list');
    var $headerNavLvl2List = $('.header-nav-l2__list');
    var $goBackEl = $('.header-nav__go-back');
    var $headerLinksEl = $('.header-links');
    var $headerSearchEl = $('.header-search');
    var $lvl2LinkEl = $('.header-nav-l2__link');
    var $mainHeaderEl = $('.main-header');
    
    var headerLvl1HasChildClass = 'header-nav-l1__item--has-child';
    var headerSectionHiddenClass = 'header-section--hidden';
    var headerActiveClass = 'main-header--submenu-open';

    /**
     * Switches submenu level
     * @param {event} e Event that function was called from
     * @param {String} direction Direction of where navigation goes to (prev/next)
     */
    function toggleSubMenu(e, direction) {
        var $toggleEl = $(e.currentTarget);
        var $parentMenu = $toggleEl.closest('ul');
        var $allParents = $toggleEl.parents('ul');
        var $subMenu = $toggleEl.next();
        var navigationLevel = parseInt($allParents.data('navigation-level'));
        var ariaExpanded = $submenuToggleEl.attr('aria-expanded');
        var expanded;

        switch (ariaExpanded) {
            case "true": {
                expanded = true;
                break;
            }
            case "false": {
                expanded = false;
                break;
            }
            default: {
                break;
            }
        }

        var levelToShow;
        
        if (direction === "prev") {
            levelToShow = navigationLevel - 1;
            $parentMenu.addClass(headerSectionHiddenClass);
        } else {
            levelToShow = navigationLevel + 1;
            $subMenu.removeClass(headerSectionHiddenClass);
        }

        var levelToShow = direction === "prev" ? navigationLevel - 1 : navigationLevel + 1;

        var topLvlPreviousActiveClass = `header-nav-l1__list--l${navigationLevel}-active`;
        var topLvlActiveClass = `header-nav-l1__list--l${levelToShow}-active`;

        $submenuToggleEl.attr('aria-expanded', !expanded);

        if (levelToShow === 1) {
            $headerLinksEl.removeClass(headerSectionHiddenClass);
            $headerSearchEl.removeClass(headerSectionHiddenClass);
        } else {
            $headerLinksEl.addClass(headerSectionHiddenClass);
            $headerSearchEl.addClass(headerSectionHiddenClass);
        }

        $topLevelMenuEl.removeClass(topLvlPreviousActiveClass);
        $topLevelMenuEl.addClass(topLvlActiveClass);
    }

    function parseLvl2() {
        $lvl2LinkEl.each(function() {
            var levelLabel = $(this).text();
            var $childGoBackEl = $(this).next().next().find('.icon-arrow-right');
            $childGoBackEl.after(`<span>${levelLabel}</span>`);
        });
        $headerNavLvl2List.each(function() {
            $(this).parent().addClass(headerLvl1HasChildClass);
        });

        var $parentLvl1LinkEl = $('.header-nav-l1__item--has-child');

        $parentLvl1LinkEl.hover(function() {
            hoverParentLvl1();
        });
    }
    
    // Toggles lvl 1 wrapper class
    function hoverParentLvl1() {
        if (window.innerWidth > 1149) {
            $mainHeaderEl.toggleClass(headerActiveClass);
        }
    }

    // Binds all the events
    function bind() {
        $submenuToggleEl.click(function(e) {
            toggleSubMenu(e, 'next');
        });
        $goBackEl.click(function(e) {
            toggleSubMenu(e, 'prev');
        });
    }

    /**
     * Initialisation
     */
    return {
         init: function() {
            bind();
            parseLvl2();
        }
    }
})();

(function() {
    headerMobileMenu.init();
}(jQuery));